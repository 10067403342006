// extracted by mini-css-extract-plugin
export var aboutOutline = "projects-module--aboutOutline--4877a";
export var activeLink = "projects-module--activeLink--cbe09";
export var albumGridContainer = "projects-module--albumGridContainer--0099f";
export var allmusicLink = "projects-module--allmusicLink--0da6f";
export var center = "projects-module--center--bd226";
export var contactOutline = "projects-module--contactOutline--221e9";
export var corner = "projects-module--corner--0d60f";
export var footerTop = "projects-module--footerTop--ac158";
export var grid = "projects-module--grid--92d91";
export var gridContainer = "projects-module--gridContainer--54c11";
export var img = "projects-module--img--e0f08";
export var layoutContainer = "projects-module--layoutContainer--78713";
export var link = "projects-module--link--5027a";
export var navLink = "projects-module--navLink--45f15";
export var projectsOutline = "projects-module--projectsOutline--36fdb";
export var sidebar___projects = "projects-module--sidebar___projects--2309c";
export var spotify = "projects-module--spotify--cf529";
export var spotifyLink = "projects-module--spotifyLink--0ce93";
export var video = "projects-module--video--369f4";
export var videoContainer = "projects-module--videoContainer--a41c3";